<template>
  <div class="guia mx-2">
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>
            Guías modulo recepción
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="12" order="2" order-md="1">
                <v-list dense nav>
                  <v-list-item>
                    <h2>Recepción</h2>
                  </v-list-item>
                  <v-list-group
                    v-for="(item, index) in list"
                    :key="index"
                    :prepend-icon="item.icon"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </template>
                    <v-list-group
                      v-for="(subItem, subIndex) in item.group"
                      :key="subIndex"
                      sub-group
                      no-action
                      ><template v-slot:activator>
                        <v-list-item-title>{{
                          subItem.item
                        }}</v-list-item-title>
                      </template>
                      <v-list-item
                        v-for="(video, v_index) in subItem.subGroup"
                        :key="v_index"
                      >
                        <v-list-item-title>{{
                          video.subItem
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list-group>
                </v-list>
              </v-col>
              <v-col cols="12" md="8" sm="12" order="1" order-md="2">
                <v-card>
                  <v-card-text>
                    <center>
                      <iframe
                        width="660"
                        height="415"
                        src="https://www.youtube.com/embed/9vCR9rYarhQ?autoplay=1"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </center>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    list: [
      {
        title: "Agenda",
        icon: "mdi-calendar",
        group: [
          {
            item: "Link de registro | Agenda Web",
            subGroup: [
              {
                subItem: "Registro Web",
                video: "",
              },
              {
                subItem: "Agenda Web",
                video: "",
              },
            ],
          },
          {
            item: "Agenda",
            subGroup: [
              {
                subItem: "Filtrar agenda",
                video: "",
              },
              {
                subItem: "Agenda completa",
                video: "",
              },
              {
                subItem: "Cancelar cita",
                video: "",
              },
              {
                subItem: "Confirmar cita",
                video: "",
              },
              {
                subItem: "Recordatorio de cita",
                video: "",
              },
              {
                subItem: "Proceso técnico",
                video: "",
              },
              {
                subItem: "Reagendar cita",
                video: "",
              },
              {
                subItem: "Notas",
                video: "",
              },
              {
                subItem: "Cuadro clínico",
                video: "",
              },
              {
                subItem: "Procesar cita",
                video: "",
              },
              {
                subItem: "Pago rápido",
                video: "",
              },
              {
                subItem: "Pago distribuido",
                video: "",
              },
            ],
          },
          {
            item: "Tomar cita",
            subGroup: [{ subItem: "Tomar cita", video: "" }],
          },
          {
            item: "Agenda privada",
            subGroup: [{ subItem: "Agenda privada", video: "" }],
          },
          {
            item: "Ventas",
            subGroup: [
              {
                subItem: "Otras ventas",
                video: "",
              },
              {
                subItem: "Ventas a crédito",
                video: "",
              },
              {
                subItem: "Descuento a facturas de crédito",
                video: "",
              },
            ],
          },
          {
            item: "Servicios",
            subGroup: [{ subItem: "Modificar valores", video: "" }],
          },
          {
            item: "Clientes",
            subGroup: [
              {
                subItem: "Filtrar clientes",
                video: "",
              },
              {
                subItem: "Crear cliente",
                video: "",
              },
              {
                subItem: "Editar cliente",
                video: "",
              },
              {
                subItem: "Editar puntos cliente",
                video: "",
              },
              {
                subItem: "Activar / Desactivar cliente",
                video: "",
              },
              {
                subItem: "Eliminar cliente",
                video: "",
              },
            ],
          },
          {
            item: "Bloqueos",
            subGroup: [
              {
                subItem: "Prof individual",
                video: "",
              },
              {
                subItem: "Prof individual fechas múltiples",
                video: "",
              },
              {
                subItem: "Prof individual rango de fechas",
                video: "",
              },
              {
                subItem: "Prof múltiples",
                video: "",
              },
              {
                subItem: "Prof múltiples fechas múltiples",
                video: "",
              },
            ],
          },
          {
            item: "Bonos regalo",
            subGroup: [
              {
                subItem: "Filtrar bono",
                video: "",
              },
              {
                subItem: "Crear bono",
                video: "",
              },
              {
                subItem: "Usar bono",
                video: "",
              },
              {
                subItem: "Anular bono",
                video: "",
              },
            ],
          },
          {
            item: "Anticipos",
            subGroup: [
              {
                subItem: "Filtrar anticipo",
                video: "",
              },
              {
                subItem: "Crear anticipo",
                video: "",
              },
              {
                subItem: "Usar anticipo",
                video: "",
              },
              {
                subItem: "Anular anticipo",
                video: "",
              },
            ],
          },
          {
            item: "Abonos por cobrar",
            subGroup: [
              {
                subItem: "Abonar",
                video: "",
              },
              {
                subItem: "Anular abono",
                video: "",
              },
            ],
          },
        ],
      },
      {
        title: "Caja",
        icon: "mdi-cash-register",
        group: [
          {
            item: "Gastos",
            subGroup: [
              {
                subItem: "Filtrar categoría",
                video: "",
              },
              {
                subItem: "Registrar gasto",
                video: "",
              },
              {
                subItem: "Anular Gasto",
                video: "",
              },
            ],
          },
          {
            item: "Liquidación profesional",
            subGroup: [
              {
                subItem: "Liquidación",
                video: "",
              },
              {
                subItem: "Agregar comisión",
                video: "",
              },
              {
                subItem: "Cargar propina",
                video: "",
              },
            ],
          },
          {
            item: "Abonos a prestamos",
            subGroup: [
              {
                subItem: "Abonar",
                video: "",
              },
              {
                subItem: "Anular abono",
                video: "",
              },
            ],
          },
        ],
      },
    ],
  }),
  mounted() {
    this.$store.dispatch("loadNav", true);
  },
};
</script>
